import bowser from "bowser";
import { resolve } from "url";

import config from "../config";
import { Locale } from "../locale/constants";
import ApiError from "./ApiError";
import {
  ApiResponse,
  ApiResult,
  ResponseError,
  ResponseStatus,
  ServerApiOption,
} from "./types";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Origin: "https://corporates.ctv.traveloka.com",
  Referer: "https://corporates.ctv.traveloka.com",
};

/**
 * To support server side call API.
 * Cannot call hooks in server side.
 * @param option
 * @param locale
 * @returns
 */
export default function serverApi<Response = unknown, Request = unknown>(
  option: ServerApiOption,
  locale: Locale
) {
  const {
    domain,
    method,
    path,
    payload: optionPayload = {},
    clientInterface = "desktop",
    showLog,
    cacheTime = 60, // 60 seconds / 1 minutes,
  } = option;
  const { apiHost } = config;

  return async (payload: Request): Promise<ApiResult<Response>> => {
    const options: RequestInit = {
      headers: defaultHeaders,
      method,
    };
    const usedPayload = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- intentional any type
      ...(optionPayload as Record<string, any>),
      ...payload,
    };

    if (method === "post") {
      // Quick fix to pass `trackingSpec` outside of `data`
      let trackingSpec: Record<string, string> | undefined;
      if ("trackingSpec" in usedPayload) {
        const browser = bowser
          .getParser(window.navigator.userAgent)
          .getBrowser();

        trackingSpec = Object.assign({}, usedPayload.trackingSpec, {
          webUrl: window.location.href,
          webReferrer: null,
          webBrowser: browser.name,
          webBrowserVersion: browser.version,
        });

        delete usedPayload.trackingSpec;
      }

      options.body = JSON.stringify({
        clientInterface,
        context: {},
        data: usedPayload,
        fields: [],
        trackingSpec,
      });
    }

    if (!options.next) {
      options.next = {};
    }
    options.next.revalidate = cacheTime;

    const apiUrl = resolve(
      apiHost[domain] + locale + "/",
      path.replace(/^\/+/, "")
    );
    const res = await fetch(apiUrl, options)
      .then<ApiResponse<Response>>((res) => res.json())
      .catch((err) =>
        console.error("Server API call failed", path, usedPayload, err)
      );

    if (showLog) {
      console.log("API URL: ", apiUrl);
      console.log("Request: ", options);
      console.log("Response: ", res);
    }

    /**
     * Type guard for 404, play framework html error, and any other error resulting
     * in non-json return type
     */
    if (typeof res !== "object" || res === null) {
      printErrorMessage("Api call failed", apiUrl, usedPayload, res);
      return {
        success: false,
        error: new TypeError("Api call failed"),
      };
    }

    // Public API
    if ("errorType" in res) {
      const { errorType } = res;

      let errorMessage = res.userErrorMessage;
      if (errorType === ResponseError.NOT_AUTHORIZED) {
        errorMessage = res.errorMessage;
      }
      printErrorMessage("Public API, has errorType", apiUrl, usedPayload, res);
      return {
        success: false,
        error: new ApiError(errorType, errorMessage),
      };
    }

    // CTV API
    if ("status" in res && res.status !== ResponseStatus.OK) {
      const { status, errorMessage } = res;
      printErrorMessage("CTV API", apiUrl, usedPayload, res);
      return {
        success: false,
        error: new ApiError(status, errorMessage),
      };
    }

    return {
      success: true,
      data: res.data,
      // Quick inject trackingSpec
      trackingSpec: res.trackingSpec,
    };
  };
}

function printErrorMessage(info: string, path: string, payload: any, res: any) {
  console.error(
    `======================\n${info}.\nURL: ${path}\nRequest: ${JSON.stringify(
      payload
    ).slice(0, 100)}\nResponse: ${JSON.stringify(res)}\n`
  );
}
